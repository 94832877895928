import React from "react";
import Template from '../components/Template/Template';
import Faq from '../sections/Faq/Faq';

const Marki = () => {

    return (
        <Template path="/faq">
           
           <Faq />
    

        </Template>
    )
}

export default Marki
